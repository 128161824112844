
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import SearchPage from '@/components/SearchPage.vue'


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    SearchPage
  }
}
