import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ce04533"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchPage = _resolveComponent("SearchPage")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchPage)
  ]))
}